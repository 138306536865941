<template>
  <div class="html">
    <div class="container">
      <input type="search" placeholder="رویدادم را پیدا کن" />
    </div>

    <div v-if="mainvideo" class="ted">
      <div class="img">
        <video
          :src="mainvideo.main_video_file"
          controls
          playsinline
          poster="@/assets/img/iri.jpg"
        ></video>
      </div>
      <div class="txt">
        <h1></h1>
        <div class="myTxt">
          <p>{{ mainvideo.title }}</p>
          <p>{{ mainvideo.description }}</p>
        </div>
      </div>
    </div>
    <div v-else></div>

    <div class="btnfilter">
      <button>
        دسته بندی رویدادها
        <ArrowDownRed />
      </button>
    </div>

    <div class="events">
      <p>محبوب ترین سخنرانی ها</p>
      <div class="line"></div>
    </div>

    <div class="eventscontent">
      <div
        v-for="(videoTable, index) in [
          videotables1,
          videotables2,
          videotables3,
        ]"
        :key="index"
        class="card"
      >
        <video
          class="videoTable"
          :src="videoTable.video_file"
          controls
          playsinline
        ></video>
        <p>{{ videoTable.title }}</p>
        <p>{{ videoTable.description }}</p>
      </div>
      <div
        v-if="
          ![videotables1, videotables2, videotables3].some((v) => v.video_file)
        "
      ></div>
    </div>

    <div class="events">
      <p>برترین سخنرانی ها</p>
      <div class="line"></div>
    </div>

    <div class="eventscontent">
      <div
        v-for="(videoTable, index) in [
          videotables4,
          videotables5,
          videotables6,
        ]"
        :key="index"
        class="card"
      >
        <video
          class="videoTable"
          :src="videoTable.video_file"
          controls
          playsinline
        ></video>
        <p>{{ videoTable.title }}</p>
        <p>{{ videoTable.description }}</p>
      </div>
      <div
        v-if="
          ![videotables4, videotables5, videotables6].some((v) => v.video_file)
        "
      ></div>
    </div>

    <div id="eventsContent" class="events">
      <p>رویداد ها</p>
      <div class="line"></div>
      <EventInformation />
    </div>

    <div class="events">
      <p>سخنران ها</p>
      <div class="line"></div>

      <div class="eventscontent">
        <div class="SpeakersCards">
          <img :src="spokesman1.image" />
          <div class="SpeakersAbsolute">
            <SpokesMan1 />
          </div>
        </div>
        <div class="SpeakersCards">
          <img :src="spokesman2.image" />
          <div class="SpeakersAbsolute">
            <SpokesMan2 />
          </div>
        </div>
        <div class="SpeakersCards">
          <img :src="spokesman3.image" />
          <div class="SpeakersAbsolute">
            <SpokesMan3 />
          </div>
        </div>
        <div class="SpeakersCards">
          <img :src="spokesman4.image" />
          <div class="SpeakersAbsolute">
            <SpokesMan4 />
          </div>
        </div>
        <div class="SpeakersCards">
          <img :src="spokesman5.image" />
          <div class="SpeakersAbsolute">
            <SpokesMan5 />
          </div>
        </div>
        <div class="SpeakersCards">
          <img :src="spokesman6.image" />
          <div class="SpeakersAbsolute">
            <SpokesMan6 />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ArrowDownRed from "@/assets/svg , icon/ArrowDownRed.vue";
import SpokesMan1 from "@/components/Spoksman1to6/SpoksMan1.vue";
import SpokesMan2 from "@/components/Spoksman1to6/SpoksMan2.vue";
import SpokesMan3 from "@/components/Spoksman1to6/SpoksMan3.vue";
import SpokesMan4 from "@/components/Spoksman1to6/SpoksMan4.vue";
import SpokesMan5 from "@/components/Spoksman1to6/SpoksMan5.vue";
import SpokesMan6 from "@/components/Spoksman1to6/SpoksMan6.vue";
import EventInformation from "@/components/Content/EventInformation.vue";

export default {
  components: {
    SpokesMan1,
    SpokesMan2,
    SpokesMan3,
    SpokesMan4,
    SpokesMan5,
    SpokesMan6,
    ArrowDownRed,
    EventInformation,
  },
  data() {
    return {
      spokesmen: [], // آرایه سخنران‌ها
      spokesman1: { image: "" },
      spokesman2: { image: "" },
      spokesman3: { image: "" },
      spokesman4: { image: "" },
      spokesman5: { image: "" },
      spokesman6: { image: "" },
      mainvideo: { title: "", description: "", main_video_file: "" },
      videotables1: { video_file: "", title: "", description: "" },
      videotables2: { video_file: "", title: "", description: "" },
      videotables3: { video_file: "", title: "", description: "" },
      videotables4: { video_file: "", title: "", description: "" },
      videotables5: { video_file: "", title: "", description: "" },
      videotables6: { video_file: "", title: "", description: "" },
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        const response = await axios.get("/api");
        this.mainvideo = response.data.mainvideo;
        this.videotables1 = response.data.videotables.category1[0];
        this.videotables2 = response.data.videotables.category2[0];
        this.videotables3 = response.data.videotables.category3[0];
        this.videotables4 = response.data.videotables.category4[0];
        this.videotables5 = response.data.videotables.category5[0];
        this.videotables6 = response.data.videotables.category6[0];
        this.spokesmen = response.data.spokesman; // بارگذاری آرایه سخنران‌ها
        this.spokesman1 = response.data.spokesman[0]; // بارگذاری آرایه سخنران‌ها
        this.spokesman2 = response.data.spokesman[1]; // بارگذاری آرایه سخنران‌ها
        this.spokesman3 = response.data.spokesman[2]; // بارگذاری آرایه سخنران‌ها
        this.spokesman4 = response.data.spokesman[3]; // بارگذاری آرایه سخنران‌ها
        this.spokesman5 = response.data.spokesman[4]; // cd distبارگذاری آرایه سخنران‌ها
        this.spokesman6 = response.data.spokesman[5]; // بارگذاری آرایه سخنران‌ها
      } catch (error) {
        console.error("Error loading data:", error);
      }
    },
  },
};
</script>

<style scoped src="@/assets/css/content.css"></style>
