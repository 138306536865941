import { createApp } from 'vue';
import App from './App.vue';
import axios from 'axios';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// تنظیم base URL برای Axios
axios.defaults.baseURL = 'https://i.irirooydad.ir/'; // آدرس دامنه API شما
// ایجاد اپلیکیشن Vue و اضافه کردن axios به Vue
const app = createApp(App);
app.config.globalProperties.$axios = axios; // برای دسترسی آسان به axios در کامپوننت‌ها
app.mount('#app');