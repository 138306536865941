<template>
  <div class="container">
    <header>
      <HeaderCnt />
    </header>
    <main>
      <ContentCnt />
      <SwiperSlider />
      <TheGeuests />
    </main>
    <section style="margin-top: 1rem">
      <SectionCnt />
    </section>
    <footer>
      <FooterCnt />
    </footer>
  </div>
</template>

<script>
import HeaderCnt from "@/components/HeaderCnt.vue";
import ContentCnt from "@/components/ContentCnt.vue";
import SwiperSlider from "@/components/Swiper/SwiperSlider.vue";
import SectionCnt from "@/components/SectionCnt.vue";
import TheGeuests from "@/components/TheGeuests.vue";
import FooterCnt from "@/components/FooterCnt.vue";

export default {
  name: "App",
  components: {
    HeaderCnt,
    ContentCnt,
    SwiperSlider,
    SectionCnt,
    TheGeuests,
    FooterCnt,
  },
};
</script>

<style>
@font-face {
  font-family: W_yekan;
  src: url("../src/assets/font/W_yekan/Yekan.eot");
  src: url("../src/assets/font/W_yekan/Yekan.ttf");
  src: url("../src/assets/font/W_yekan/Yekan.woff");
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* @media only screen and (min-width : 1930px){
  .container{
    width : 1930px;
  }
} */
</style>
