<template>
  <p class="News">خبر ها</p>
  <div class="line"></div>
  <swiper
    :spaceBetween="30"
    :slidesPerView="1"
    :pagination="{ clickable: true }"
    :autoplay="{ delay: 3000, disableOnInteraction: false }"
    :modules="modules"
    :breakpoints="{
      640: { slidesPerView: 1 },
      768: { slidesPerView: 2 },
      1024: { slidesPerView: 3 },
      1280: { slidesPerView: 4 },
    }"
    class="mySwiper"
  >
    <swiper-slide v-for="news in newsItems" :key="news.id">
      <img :src="news.poster" alt="News Image" />
      <div class="display">
        <h3>{{ news.title }}</h3>
        <p>{{ news.news_text }}</p>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script>
// Import Swiper Vue.js components and styles
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";

// Import required modules
import { Pagination, Autoplay } from "swiper/modules";
import axios from "axios";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      modules: [Pagination, Autoplay],
      newsItems: [], // Array for storing news data
    };
  },
  methods: {
    async fetchNews() {
      try {
        const response = await axios.get("/api"); // آدرس API خود را جایگزین کنید
        this.newsItems = response.data.news; // اینجا داده‌های زیرمجموعه news را اختصاص می‌دهیم
      } catch (error) {
        console.error("خطا در دریافت داده‌های خبر:", error);
      }
    },
  },
  mounted() {
    this.fetchNews(); // فراخوانی متد دریافت داده‌ها هنگام لود شدن
  },
};
</script>

<style scoped>
#app {
  height: 100%;
}

html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 90%;
  height: 100%;
  margin-top: 1.5rem;
}

.swiper-slide img {
  position: relative;
  background: #fff;
  object-fit: cover;
  width: 100%;
  height: 400px;
  color: #f1f1f1;
  background-color: rgba(255, 255, 255, 0.164);
  filter: blur(2px);
  display: flex;
  align-items: center;
  border-radius: 2rem;
  justify-content: center;
}

.display {
  width: 100%;
  height: 100%;
  top: 0rem;
  right: 0rem;
  position: absolute;
  color: #f1f1f1;
  background: #45485383;
  font-family: W_yekan;
  font-size: 24px;
  border-radius: 2rem;
  text-align: center;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
}

.News {
  font-family: W_yekan;
  color: #707070;
  margin-top: 5%;
  text-align: center;
  font-weight: bold;
}

.line {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 2% auto auto auto;
  height: 1px;
  background: #ef1746;
}
</style>
